import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CurrencyService } from '../../services/currency.service';
import { LanguageService } from '@app/i18n';
import { BehaviorSubject, map } from 'rxjs';

@Component({
    selector: 'app-currency-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrencyMenuComponent {
    open$ = new BehaviorSubject(false);
    currency$ = this.currencyService.currency$;
    currencies = this.currencyService.currencies;
    language$ = this.languageService.language$.pipe(map((language) => language.split('-')[0]));

    constructor(private currencyService: CurrencyService, private languageService: LanguageService) {}

    onClick(event: MouseEvent): void {
        event.preventDefault();
        this.open$.next(!this.open$.value);
    }

    onOutsideClick() {
        this.open$.next(false);
    }

    onSubmitEvent() {
        this.open$.next(false);
    }
}
