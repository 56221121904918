<div class="d-flex align-items-center flex-nowrap">
    <span class="fs-13 currency me-5 d-none d-sm-block d-lg-none d-xl-block">{{ 'currency.select-currency-and-language' | translate }}:</span>
    <div class="pointer d-flex align-items-center text-secure" (click)="onClick($event)">
        <div class="currency pe-5 me-5 border-end border-gray-200 d-flex align-items-center flex-nowrap">
            <img src="assets/svgs/optimized-flags/{{ language$ | async }}.svg" />
            <span class="fs-13 fw-700 text-gray-600 text-uppercase">{{ language$ | async }}</span>
        </div>
        <div class="fs-13 fw-700 text-gray-600 text-uppercase currency">{{ currency$ | async }}</div>
        <span
            class="icon icon-7 icon-gray-600 icon-caret"
            [class.rotate-90]="(open$ | async) === false"
            [class.rotate-270]="(open$ | async) === true"
            appIcon="caret"
        ></span>
    </div>
    <app-currency-dropdown *ngIf="open$ | async" (outsideClick)="onOutsideClick()" (submitEvent)="onSubmitEvent()" appFloating></app-currency-dropdown>
</div>
