import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@app/shared';
import { DirectivesModule } from '@app/shared/directives';
import { ThemeFormsModule } from '@app/shared/forms';

import { CurrencyMenuComponent } from './components/menu/menu.component';
import { CurrencyDropdownComponent } from './components/dropdown/dropdown.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PricePipe } from './pipes/currency/price.pipe';

@NgModule({
    imports: [CommonModule, ReactiveFormsModule, SharedModule, DirectivesModule, TranslateModule, ThemeFormsModule],
    declarations: [CurrencyMenuComponent, CurrencyDropdownComponent, PricePipe],
    exports: [CurrencyMenuComponent, PricePipe],
})
export class CurrencyModule {}
