<div class="box border border-secondary bg-white p-8 rounded mt-8">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="mb-8">
            <app-form-dropdown-language
                [label]="'currency.dropdown.select-language' | translate"
                formControlName="language"
                [options]="lanugages"
                size="sm"
                [block]="true"
            ></app-form-dropdown-language>
        </div>
        <div class="mb-8">
            <app-form-dropdown
                [label]="'currency.dropdown.select-currency' | translate"
                formControlName="currency"
                [options]="currencies"
                size="sm"
                [block]="true"
            ></app-form-dropdown>
        </div>
        <button class="btn btn-sm btn-secondary btn-block">
            {{ 'button.apply' | translate }}
        </button>
    </form>
</div>
