import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyService } from '@app/currency/services/currency.service';
import { TransitPrice } from '@app/store/transits/models';
import { map, Observable } from 'rxjs';

@Pipe({
    name: 'price',
})
export class PricePipe implements PipeTransform {
    constructor(private currencyService: CurrencyService) {}
    transform(value: TransitPrice[]): Observable<number | string> {
        return this.currencyService.currency$.pipe(
            map((currency) => {
                const price = value.find((item) => item.currency === currency);
                if (price !== undefined) {
                    return `${price.value} ${currency}`;
                }
                return '-';
            }),
        );
    }
}
